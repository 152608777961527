/*
	Josh Comeau
	CSS Custom Reset
	https://www.joshwcomeau.com/css/custom-css-reset/
*/
/*
	1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
	box-sizing: border-box;
}
/*
	2. Remove default margin
  */
* {
	margin: 0;
}
/*
	3. Allow percentage-based heights in the application
  */
html,
body {
	height: 100%;
}
/*
	Typographic tweaks!
	4. Add accessible line-height
	5. Improve text rendering
  */
body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}
/*
	6. Improve media defaults
  */
img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}
/*
	7. Remove built-in form typography styles
  */
input,
button,
textarea,
select {
	font: inherit;
}
/*
	8. Avoid text overflows
  */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}
/*
	9. Create a root stacking context
  */
#root,
#__next {
	isolation: isolate;
}
/*
	End Custom Reset
*/

body {
	background-color: #1e1e2e;
	color: #cdd6f4;
	font-family: 'Font Awesome 5 Regular', sans-serif;
	font-size: x-large;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 405px;
	margin: 0;
}

button {
	border: 0;
	color: inherit;
	/* background: none; */
	background-color: #313244;
	box-shadow: none;
	border-radius: 6px;
}

a {
	color: inherit;
	text-decoration: inherit;
}

body div {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.footer {
	display: flex;
	justify-content: space-around;
	padding: 50px 0px;
	font-size: 18px;
	color: #e5e7eb;
}